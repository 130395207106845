import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import log from "loglevel";
import { useContext, useMemo, useState } from "react";
import { ALL_SIFTS } from "../libs/constants";
import { getIam, postIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import dayjs from "dayjs";

const getSifts = async (
  product: string,
  ctx: any,
  setSifts: Function,
  email?: string,
  siftId?: string
) => {
  const resp = await getIam("/api/admin/sifts", {
    guid: product,
    email,
    siftId,
  });
  if (resp.error) {
    log.error("getSifts::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    setSifts(resp);
  }
};

const getPublished = async (product: string, setPublished: Function) => {
  const resp = await getIam("/api/admin/update", {
    guid: product,
  });
  if (resp.error) {
    log.error("getPublished::", resp);
  } else {
    log.debug("getPublished::", resp);
    setPublished(resp);
  }
};

const updateSift = async (product: string, siftIds: any[], ctx: any) => {
  const resp: any = await postIam("/api/admin/update", {
    guid: product,
    siftIds,
    sha: "TODO", // TODO: send SHA when we support updates not to the published version
  });
  if (resp.error) {
    log.error("updateSift::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert("Update completed", "success", 2000);
  }
};

const getAdminCreds = async (
  siftId: string,
  guid: string,
  ctx: any,
  setAdminSift: Function
) => {
  const resp = await getIam("/api/admin/sift", {
    siftId,
    guid,
  });
  if (resp.error) {
    ctx.setAlert(resp.error, "error");
  } else {
    log.debug("AdminSiftExplorer::manage", resp);
    setAdminSift(resp);
  }
};

const AdminSiftExplorer = ({ setAdminSift }: { setAdminSift: Function }) => {
  const ctx = useContext(AppContext);
  const [isManageLoading, setIsManageLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState("");
  const [email, setEmail] = useState("");
  const [siftId, setSiftId] = useState("");
  const [sifts, setSifts] = useState([]);
  const [published, setPublished] = useState<any>(undefined);
  const handleProductChange = (event: SelectChangeEvent) => {
    setSifts([]);
    setPublished(undefined);
    setProduct(event.target.value as string);
  };

  const columns = useMemo(
    () => [
      {
        field: "_id",
        headerName: "MongoDb Id",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "id",
        headerName: "Sift Id",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "sha",
        headerName: "SHA",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "identity",
        headerName: "Sift Identity",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "emailRoles",
        headerName: "Email : Roles",
        flex: 1,
        valueGetter: ({ value }: any): any => `${value.join(" | ")}`,
        valueParser: ({ value }: any) => value.toString(),
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "uids",
        headerName: "User Ids",
        flex: 1,
        valueGetter: ({ value }: any) => `${value.join(" | ")}`,
        valueParser: ({ value }: any) => value.toString(),
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "metadata.name",
        headerName: "Name",
        flex: 1,
        valueGetter: ({ row }: any) => row.metadata?.name,
        valueParser: ({ row }: any) => row.metadata?.name,
        renderCell: ({ row }: any) => (
          <TooltipCell value={row.metadata?.name} />
        ),
      },
      {
        field: "metadata.description",
        headerName: "Description",
        valueGetter: ({ row }: any) => row.metadata?.description,
        valueParser: ({ row }: any) => row.metadata?.description,
        renderCell: ({ row }: any) => (
          <TooltipCell value={row.metadata?.description} />
        ),
      },
      {
        field: "metadata.userCount",
        headerName: "User Count",
        valueGetter: ({ row }: any) => row.metadata?.userCount,
        valueParser: ({ row }: any) => row.metadata?.userCount,
        renderCell: ({ row }: any) => (
          <TooltipCell value={row.metadata?.userCount} />
        ),
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        type: "dateTime",
        valueGetter: ({ row }: any) => {
          if (!row?.createdAt) {
            return null;
          } else {
            return new Date(row.createdAt);
          }
        },
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={
              value ? dayjs(value).format("DD/MM/YYYY") : "No Date Available"
            }
          />
        ),
      },
      {
        field: "deletedAt",
        headerName: "Soft deleted Date",
        type: "dateTime",
        valueGetter: ({ row }: any) => {
          if (!row?.deletedAt) {
            return null;
          } else {
            return new Date(row.deletedAt);
          }
        },
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={value ? dayjs(value).format("DD/MM/YYYY") : "-"}
          />
        ),
      },
      {
        field: "manage",
        headerName: "Manage",
        renderCell: ({ row }: any) =>
          ALL_SIFTS[product].canAdmin && (
            <LoadingButton
              loading={isManageLoading}
              onClick={async () => {
                setIsManageLoading(true);
                await getAdminCreds(row.id, product, ctx, setAdminSift);
                setIsManageLoading(false);
              }}
              variant="outlined"
            >
              Manage
            </LoadingButton>
          ),
      },
      {
        field: "update",
        headerName: "Update",
        renderCell: ({ row }: any) => (
          <LoadingButton
            loading={isUpdateLoading}
            onClick={async () => {
              setIsUpdateLoading(true);
              await updateSift(product, [[row.id, row.emailRoles]], ctx);
              await getSifts(product, ctx, setSifts, email, siftId);
              setIsUpdateLoading(false);
            }}
            variant="contained"
            color="error"
          >
            Update
          </LoadingButton>
        ),
      },
    ],
    [
      product,
      isManageLoading,
      ctx,
      setAdminSift,
      isUpdateLoading,
      email,
      siftId,
    ]
  );

  return (
    <div>
      <form
        style={{ display: "flex", flexFlow: "wrap" }}
        onSubmit={async (ev) => {
          ev.preventDefault();
          setLoading(true);
          await getSifts(product, ctx, setSifts, email, siftId);
          await getPublished(product, setPublished);
          setLoading(false);
        }}
      >
        <FormControl style={{ minWidth: 200, margin: "5px 0px 0px 0px" }}>
          <InputLabel id="product-selector-label">Product</InputLabel>
          <Select
            labelId="product-selector-label"
            id="product-selector"
            value={product}
            label="Product"
            onChange={handleProductChange}
            required
          >
            {Object.keys(ALL_SIFTS)
              .sort((a: any, b: any) =>
                ALL_SIFTS[a].name.toLowerCase() >
                ALL_SIFTS[b].name.toLowerCase()
                  ? 1
                  : -1
              )
              .map((k, i) => (
                <MenuItem key={i} value={k}>
                  {ALL_SIFTS[k].name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          id="email-search"
          label="email (partial match)"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <TextField
          id="sift-id-search"
          label="sift id (strict match)"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          value={siftId}
          onChange={(ev) => setSiftId(ev.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          style={{ margin: "5px 0px 0px 5px" }}
        >
          Find
        </LoadingButton>
      </form>
      {!!published && (
        <div
          style={{ display: "flex", marginTop: 30, justifyContent: "flex-end" }}
        >
          <TextField
            label="Published SHA"
            style={{ minWidth: 380 }}
            value={published?.sha}
            InputProps={{
              readOnly: true,
            }}
          />
          <LoadingButton
            style={{ margin: "0px 5px 0px 5px" }}
            loading={isUpdateLoading}
            onClick={async () => {
              setIsUpdateLoading(true);
              const all: any[] = [];
              sifts.forEach((s: any) => {
                if (s.sha !== published.sha) {
                  all.push([s.id, s.emailRoles]);
                }
              });
              await updateSift(product, all, ctx);
              await getSifts(product, ctx, setSifts, email, siftId);
              setIsUpdateLoading(false);
            }}
            variant="outlined"
          >
            Update Old
          </LoadingButton>
          <LoadingButton
            loading={isUpdateLoading}
            onClick={async () => {
              setIsUpdateLoading(true);
              const all: any[] = sifts.map((s: any) => [s.id, s.emailRoles]);
              await updateSift(product, all, ctx);
              await getSifts(product, ctx, setSifts, email, siftId);
              setIsUpdateLoading(false);
            }}
            variant="contained"
            color="error"
          >
            Update All
          </LoadingButton>
        </div>
      )}
      <SearchableTable
        tableId="sifts-explorer"
        columns={columns}
        rows={sifts}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              _id: false,
              uids: false,
              "metadata.description": false,
              "metadata.userCount": false,
              "metadata.name": false,
              deletedAt: false,
              identity: false,
            },
          },
        }}
      />
    </div>
  );
};

export default AdminSiftExplorer;
